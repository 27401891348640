import { type FC, memo } from 'react';
import { IpynbRenderer } from 'react-ipynb-renderer';
import { Box, type BoxProps, useColorScheme } from '@mui/joy';

import useIpynbData from './model/useIpynbData';
import useIpynbTheme from './model/useIpynbTheme';

interface IpynbViewerProps extends BoxProps {
  content?: string | null;
}

const IpynbViewer: FC<IpynbViewerProps> = (props) => {
  const { content, ...rest } = props;
  const { mode } = useColorScheme();

  useIpynbTheme(mode);
  const ipynb = useIpynbData(content);

  return <Box {...rest}>{ipynb && <IpynbRenderer ipynb={ipynb} bgTransparent syntaxTheme={mode === 'light' ? 'solarizedlight' : 'dark'} />}</Box>;
};

export default memo(IpynbViewer);
