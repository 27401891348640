import { type FC, memo, useEffect, useRef } from 'react';
import { Typography, Box, useTheme } from '@mui/joy';

import { usePerson } from 'hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';
import type { ItemAttribute, ItemId, QueryType } from 'widgets/Copilot/store/types';

import Avatar from 'components/Avatar';

export interface QuestionProps {
  id: ItemId;
  attribute: ItemAttribute;
  onMount?: () => void;
}

const Question: FC<QuestionProps> = (props) => {
  const { id, attribute, onMount } = props;

  const theme = useTheme();

  const mountRef = useRef(false);
  const { person } = usePerson('User', 'my');
  const data = useSelector(copilotStore.selectors.dataById<QueryType>(id));

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      onMount?.();
    }
  }, [onMount]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      borderRadius="var(--joy-radius-xl)"
      pl={2}
      pr={3}
      py={2}
      maxWidth="85%"
      alignSelf="flex-end"
      gap={2}
      bgcolor={theme.palette.mode === 'dark' ? theme.palette.neutral['200'] : theme.palette.neutral['700']}
    >
      <Avatar user={person} vars={{ AvatarSize: '32px' }} />
      <Typography
        sx={{
          color: theme.palette.mode === 'dark' ? theme.palette.neutral['600'] : theme.palette.neutral['300'],
        }}
      >
        {data.payload.label || data.payload.text}
      </Typography>
    </Box>
  );
};

export default memo(Question);
