import React, { useMemo } from 'react';
import { Typography } from '@mui/joy';

import type { ContentErrorType, MaterialType } from 'app/entities';
import { guard } from 'utils';

import VideoViewer from 'components/VideoViewer';
import PDFViewer from 'components/PDFViewer';
import ImageViewer from 'components/ImageViewer';
import HtmlViewer from 'components/HtmlViewer';
import IpynbViewer from 'components/IpynbViewer';

const useBodyRender = (materialType: string | null | undefined, data?: MaterialType | ContentErrorType | null) =>
  useMemo(() => {
    console.log('data', data);
    if (guard.isContentError(data) && data.__typename === 'AccessError') {
      return <Typography level="h3">You do not have access to this resource.</Typography>;
    }
    if (guard.isContentError(data) && data.__typename === 'NotFoundError') {
      return <Typography level="h3">The requested resource does not exist.</Typography>;
    }
    if (guard.isContentError(data) && data.__typename === 'UnknownError') {
      return <Typography level="h3">Unknown error, please try again later.</Typography>;
    }
    if (!guard.isMaterial(data)) {
      return null;
    }
    if (materialType === 'video') {
      return <VideoViewer src={data?.originalUrl} content={data?.content?.htmlMarkup} />;
    }
    if (materialType === 'pdf') {
      return <PDFViewer src={data?.originalUrl} limit={200} />;
    }
    if (materialType === 'image') {
      return <ImageViewer src={data?.originalUrl} />;
    }
    if (materialType === 'ipynb') {
      return <IpynbViewer content={data?.content?.htmlMarkup} />;
    }
    return <HtmlViewer content={data?.content?.htmlMarkup || data?.originalUrl} />;
  }, [
    materialType,
    data?.__typename,
    (data as MaterialType)?.content?.htmlMarkup,
    (data as MaterialType)?.originalUrl,
    (data as MaterialType)?.content,
  ]);

export default useBodyRender;
