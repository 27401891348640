import { type FC, type MouseEventHandler, memo, useCallback } from 'react';
import { isEqual } from 'lodash';
import { Box, Button, type ButtonProps } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';
import Icon from 'ui/Icon';

export interface DeepResearchToggleProps extends Omit<ButtonProps, 'size'> {
  size?: 'small' | 'regular';
}

const DeepResearchToggle: FC<DeepResearchToggleProps> = (props) => {
  const { size, sx, ...rest } = props;
  const dispatcher = useDispatcher();

  const scope = useSelector(askStore.selectors.scope);

  const handleToggle = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    dispatcher.ask.setScope({
      scope: scope === 'research' ? 'library-fulltext' : 'research',
    });
  }, [scope]);

  const isEnabled = scope === 'research';

  return (
    <Button
      size={size === 'small' ? 'sm' : 'md'}
      variant="outlined"
      color={isEnabled ? 'primary' : 'neutral'}
      startDecorator={
        <Box>
          <Icon name="telescope" weight={isEnabled ? 'solid' : 'light'} fw color="inherit" />
        </Box>
      }
      onClick={handleToggle}
      {...rest}
      sx={{
        position: 'relative',
        fontSize: size === 'small' ? 12 : 14,
        fontWeight: 400,
        borderColor: isEnabled ? 'var(--joy-palette-primary-outlinedBorder)' : 'transparent',
        ...sx,
      }}
    >
      <Box component="div" position="absolute" width={0} height={0} sx={{ overflow: 'hidden', opacity: 0, pointerEvents: 'none' }}>
        <Icon name="telescope" weight="solid" fw color="inherit" />
        <Icon name="telescope" weight="light" fw color="inherit" />
      </Box>
      Deep research
    </Button>
  );
};

export default memo(DeepResearchToggle, isEqual);
