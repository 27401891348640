import { type SagaReturnType, takeEvery, put, delay } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as spaceStore from 'screens/Space/store';

import { actions } from '../slice';
import * as selectors from '../selectors';

export const config = {
  action: [spaceStore.slices.spaceList.actions.set.type, spaceStore.slices.spaceList.actions.remove.type],
  method: takeEvery,
};

type SourceType = {
  id: string | number;
  name: string;
};

function* restoreState(availableSources: SourceType[]) {
  const selectedSource = yield* select(selectors.selectedSource);
  if (!availableSources.length || selectedSource) {
    return;
  }
  let loadedSourceId: string | number | null;
  try {
    loadedSourceId = JSON.parse(localStorage.getItem('ask/source') || '') as string | number;
  } catch {
    loadedSourceId = null;
  }
  if (!loadedSourceId || availableSources.findIndex((item) => item.id === loadedSourceId) === -1) {
    yield put(actions.setSelectedSource({ id: availableSources[0].id }));
    return;
  }
  yield put(actions.setSelectedSource({ id: loadedSourceId as string | number }));
}

export function* func(action: SagaReturnType<typeof spaceStore.slices.spaceList.actions.set | typeof spaceStore.slices.spaceList.actions.remove>) {
  yield delay(10);
  const list = yield* select(spaceStore.selectors.list);
  const sources = list.map((item) => ({ id: item.id, name: item.title, icon: 'folder' }));
  yield put(actions.setAvailableSources({ sources }));

  yield restoreState(sources);
}
