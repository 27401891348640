import type { Resource } from 'widgets/Copilot/store/types';
import { type FC, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { Box, Typography } from '@mui/joy';
import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

interface EmptyStateProps {
  resources: Resource[];
}

const EmptyState: FC<EmptyStateProps> = (props) => {
  const { resources } = props;

  const hasAnswer = useSelector((state) => copilotStore.selectors.sequence(resources)(state).length > 0);

  console.log('resources[0].type', resources.length, resources[0].type);
  if (resources.length === 1 && resources[0].type === 'Space' && !hasAnswer) {
    return (
      <Box alignSelf="center" my={12}>
        <Typography fontSize={12} fontWeight={400} color="neutral">
          Chat with your whole Library / Space
        </Typography>
      </Box>
    );
  }

  if (resources.length === 1 && resources[0].type === 'SpaceFolder' && !hasAnswer) {
    return (
      <Box alignSelf="center" my={12}>
        <Typography fontSize={12} fontWeight={400} color="neutral">
          Chat with this collection
        </Typography>
      </Box>
    );
  }

  if (resources.length === 1 && resources[0].type === 'material' && !hasAnswer) {
    return (
      <Box alignSelf="center" my={12}>
        <Typography fontSize={12} fontWeight={400} color="neutral">
          Chat with this material
        </Typography>
      </Box>
    );
  }

  return null;
};

export default memo(EmptyState, isEqual);
