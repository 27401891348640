import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import type { BoxProps } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';
import * as copilotStore from 'widgets/Copilot/store';

import DropdownMenu from 'ui/DropdownMenu';
import Icon from 'ui/Icon';

export interface SourceSelectProps extends Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'> {
  size?: 'small' | 'regular';
}

const SourceSelect: FC<SourceSelectProps> = (props) => {
  const { size, ...rest } = props;
  const dispatcher = useDispatcher();

  const scope = useSelector(askStore.selectors.scope);
  const selectedSource = useSelector(askStore.selectors.selectedSource);
  const availableSources = useSelector(askStore.selectors.availableSources);
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping('*'));

  const handleChange = useCallback((id: string | number) => {
    dispatcher.ask.setSelectedSource({ id: Number(id) });
  }, []);

  if (!selectedSource) {
    return null;
  }

  return (
    <DropdownMenu
      slotProps={{ menuButton: { size: size === 'small' ? 'sm' : 'md' }, typography: { fontSize: size === 'small' ? 12 : 14 } }}
      disabled={isAnswerTyping || availableSources.length === 1 || scope === 'web'}
      options={availableSources}
      value={selectedSource.id}
      labelPrefix={<Icon name="folder" weight="light" fw sx={{ opacity: 0.75, mr: 1, mt: 0.125 }} />}
      optionValueName="id"
      optionLabelName="name"
      optionIconName="icon"
      listLabel="Source"
      onChange={handleChange}
      {...rest}
    />
  );
};

export default memo(SourceSelect, isEqual);
