import { type FC, type MouseEvent, type ReactElement, type ReactNode, memo, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import {
  type DropdownProps,
  type MenuButtonProps,
  type TypographyProps,
  type BoxProps,
  type ButtonProps,
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
  Box,
  useColorScheme,
} from '@mui/joy';

import Icon from 'ui/Icon';

type OptionType<T extends string | number> = {
  id: T;
  [key: string]: any;
};

export interface DropdownMenuProps<T extends string | number>
  extends Omit<DropdownProps, 'children'>,
    Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'> {
  options: OptionType<T>[];
  value: T;
  onChange?: (value: T) => void;
  slotProps?: {
    menuButton?: MenuButtonProps;
    typography?: TypographyProps;
  };
  disabled?: boolean;
  optionValueName?: string;
  optionLabelName?: string;
  optionIconName?: string;
  listLabel?: string;
  labelPrefix?: ReactNode;
  labelSuffix?: ReactNode;
}

const DropdownMenu: FC<DropdownMenuProps<string | number>> = (props) => {
  const {
    options,
    value,
    onChange,
    disabled,
    slotProps: { menuButton, typography } = {},
    optionValueName = 'value',
    optionLabelName = 'label',
    optionIconName = 'icon',
    listLabel,
    labelPrefix,
    labelSuffix,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    ...dropdownRest
  } = props;

  const { mode } = useColorScheme();

  const handleSelect = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const newValue = event.currentTarget.getAttribute('itemid');
      if (!newValue) {
        return;
      }
      onChange?.(Number.isNaN(Number(newValue)) ? newValue : Number(newValue));
    },
    [onChange],
  );

  const menu = useMemo(
    () => (
      <Menu
        variant="plain"
        sx={{
          '--List-radius': 'var(--joy-radius-lg)',
          backgroundColor:
            mode === 'light'
              ? 'var(--joy-palette-background-popup)'
              : 'color-mix(in srgb, var(--joy-palette-background-popup) 15%, var(--joy-palette-background-level1) 85%)',
          boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
        }}
      >
        {!!listLabel && (
          <Typography textColor="text.primary" mx={2} py={1} fontSize={12} fontWeight={500} sx={{ opacity: 0.5 }}>
            {listLabel}
          </Typography>
        )}
        {options.map((option) => (
          <MenuItem
            key={option[optionValueName]}
            itemID={`${option[optionValueName]}`}
            onClick={handleSelect}
            disabled={option?.disabled}
            sx={{
              '--ListItem-paddingLeft': '0.5rem',
              '--ListItem-paddingRight': '0.5rem',
              '--ListItem-paddingX': '0.5rem',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 1,
              mx: 1,
              my: 0.25,
              borderRadius: '0.5rem',
            }}
          >
            {!!optionIconName && !!option[optionIconName] && <Icon name={option[optionIconName]} weight="light" fw sx={{ opacity: 0.75 }} />}
            {option[optionLabelName]}
            <Box flex={1} />
            {value === option[optionValueName] ? <Icon name="circle-check" weight="solid" fw /> : <Box width="1rem" />}
          </MenuItem>
        ))}
      </Menu>
    ),
    [value, options, handleSelect, optionValueName, optionLabelName, mode],
  );

  const label = useMemo(
    () => options.find((option) => option[optionValueName] === value)?.[optionLabelName] || '',
    [options, value, optionValueName, optionLabelName],
  );

  return (
    <Dropdown {...dropdownRest}>
      <MenuButton
        {...menuButton}
        slots={{
          root: Button,
          ...menuButton?.slots,
        }}
        slotProps={{
          ...menuButton?.slotProps,
          root: {
            variant: 'plain',
            color: 'neutral',
            size: 'sm',
            disabled,
            sx: {
              m,
              mx,
              my,
              mt,
              mr,
              mb,
              ml,
              ...(menuButton?.slotProps?.root as ButtonProps)?.sx,
            },
            ...(menuButton?.slotProps?.root as ButtonProps),
          },
        }}
      >
        {labelPrefix && typeof labelPrefix !== 'string' && labelPrefix}
        <Typography fontSize={16} fontWeight="400" sx={{ color: 'inherit', ...typography?.sx }} {...typography}>
          {labelPrefix && typeof labelPrefix === 'string' && labelPrefix}
          {label}
          {labelSuffix && typeof labelSuffix === 'string' && labelSuffix}
        </Typography>
        {labelPrefix && typeof labelSuffix !== 'string' && labelSuffix}
        <Icon name="angle-down" size="1x" fw weight="regular" color="inherit" ml={0.75} mt={0.25} sx={{ opacity: 0.75 }} />
      </MenuButton>
      {menu}
    </Dropdown>
  );
};

export default memo(DropdownMenu, isEqual) as <T extends string | number>(props: DropdownMenuProps<T>) => ReactElement;
