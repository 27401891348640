const contentTypes = {
  'application/pdf': 'pdf',
  'text/plain': 'txt',
  'text/rtf': 'rtf',
  'text/csv': 'csv',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'text/markdown': 'md',
  'image/pjpeg': 'jpg',
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/tiff': 'tiff',
  'image/svg+xml': 'svg',
  'image/webp': 'webp',
  'application/x-ipynb+json': 'ipynb',
} as const;

type ContentType = keyof typeof contentTypes;
type FileExtension = (typeof contentTypes)[ContentType];

const isSuitableContentType = (contentType: ContentType | unknown): contentType is ContentType => {
  if (!contentType) {
    return false;
  }
  return Object.keys(contentTypes).includes(contentType as string);
};

export const acceptContentTypes: { [key in ContentType]: [FileExtension] } = Object.fromEntries(
  Object.entries(contentTypes).map(([contentType, extension]) => [contentType as ContentType, [`.${extension}` as FileExtension]]),
) as { [key in ContentType]: [FileExtension] };

export default (contentType: ContentType | string, defaultValue: FileExtension) => {
  if (isSuitableContentType(contentType)) {
    return contentTypes[contentType];
  }
  return defaultValue;
};
