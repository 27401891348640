import { type IpynbType } from 'react-ipynb-renderer';

const useIpynbData = (content?: string | null) => {
  if (!content) {
    return null;
  }
  try {
    const parsed = JSON.parse(content) as Object;
    if (!('cells' in parsed)) {
      return null;
    }
    return parsed as IpynbType;
  } catch {
    return null;
  }
};

export default useIpynbData;
