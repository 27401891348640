// useIpynbTheme.ts
import { useEffect, useRef } from 'react';
import { ipynbLightCss, ipynbDarkCss } from './ipynbCss';

/**
 * Хук, создающий/обновляющий <style id="ipynb-styles">
 * для ipynb-renderer (light/dark).
 */
export default function useIpynbTheme(mode: 'light' | 'dark' | 'system' = 'light') {
  const styleRef = useRef<HTMLStyleElement | null>(null);

  useEffect(() => {
    let styleEl = document.getElementById('ipynb-styles') as HTMLStyleElement | null;
    if (!styleEl) {
      styleEl = document.createElement('style');
      styleEl.id = 'ipynb-styles';
      document.head.appendChild(styleEl);
    }
    styleRef.current = styleEl;

    const cssText = mode === 'dark' ? ipynbDarkCss : ipynbLightCss;

    styleEl.innerHTML = cssText;
  }, [mode]);
}
