import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ScopeType = 'web' | 'research' | 'global-fulltext' | 'library-fulltext';

export interface SequenceType {
  type: 'Material' | 'RagMaterial' | 'Collection' | 'Unknown';
  id: number | string;
}

export interface AskStore {
  sequence: SequenceType[];
  scope: ScopeType;
  query: string;
  source: {
    selectedId: number | string | null;
    available: { id: number | string; name: string; icon: string }[];
  };
}

const initialState: AskStore = {
  sequence: [],
  scope: (localStorage?.getItem('ask/scope') || 'web') as ScopeType,
  query: '',
  source: {
    selectedId: null,
    available: [],
  },
};

const slice = createSlice({
  name: 'ask',
  initialState,
  reducers: {
    setScope: (state, action: PayloadAction<{ scope: ScopeType }>) => {
      state.scope = action.payload.scope;
      localStorage?.setItem('ask/scope', action.payload.scope);
    },
    setQuery: (state, action: PayloadAction<{ query: string }>) => {
      state.query = action.payload.query;
    },
    setSequence: (state, action: PayloadAction<{ sequence: SequenceType[] }>) => {
      state.sequence = action.payload.sequence;
    },
    addSequence: (state, action: PayloadAction<{ sequence: SequenceType[] }>) => {
      state.sequence.push(...action.payload.sequence);
    },
    searchClear: () => undefined,
    setAvailableSources: (state, action: PayloadAction<{ sources: { id: number | string; name: string; icon: string }[] }>) => {
      state.source.available = action.payload.sources;
    },
    setSelectedSource: (state, action: PayloadAction<{ id: number | string }>) => {
      state.source.selectedId = action.payload.id;
    },
  },
});

export const { reducer, actions } = slice;
